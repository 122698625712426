import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration, PutConfigurationResponseDto } from '@atypes/metadata.types';

import { environment } from '@env';
import { TranslateService } from '@ngx-translate/core';
import { AppToastService } from './apptoast.service';
import { LoginService } from './login.service';

/** API URL defined in environment files. */
const API = environment.applicationApiUrl;

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  loadingRequest: boolean;
  enableFicheReading: boolean;
  enableFichePrint: boolean;
  maxSequenceRequestInterval: number;
  configurations: Configuration[];

  constructor(
    private http: HttpClient,
    private login: LoginService,
    private toastService: AppToastService,
    private translate: TranslateService
  ) {
    this.loadingRequest = true;
    this.enableFicheReading = false;
    this.enableFichePrint = false;
    this.maxSequenceRequestInterval = 30;
    this.configurations = [];
  }

  async loadConfiguration(): Promise<void> {
    try {
      this.configurations = await this.getConfiguration();
      this.configurations.map(configuration => {
        if (configuration.name === 'ficheRead') this.enableFicheReading = configuration.value === 'true';
        if (configuration.name === 'declarationPrint') this.enableFichePrint = configuration.value === 'true';
        if (configuration.name === 'maxSequenceRequestInterval') this.maxSequenceRequestInterval = parseInt(configuration.value);
      });
    } catch {
      this.toastService.error(`Ocorreu um erro ao tentar atualizar as configurações.`);
    } finally {
      this.loadingRequest = false;
    }
  }

  checksIfConfigurationsChanged(): boolean {
    return this.configurations.some(configuration => {
      if (configuration.name === 'ficheRead') return this.enableFicheReading !== (configuration.value === 'true');
      if (configuration.name === 'declarationPrint') return this.enableFichePrint !== (configuration.value === 'true');
    });
  }

  async updateConfiguration(): Promise<void> {
    this.loadingRequest = true;

    try {
      for (const configuration of this.configurations) {
        if (configuration.name === 'ficheRead' && this.enableFicheReading !== (configuration.value === 'true')) {
          await this.putConfiguration(configuration.name, this.enableFicheReading.toString());
        }

        if (configuration.name === 'declarationPrint' && this.enableFichePrint !== (configuration.value === 'true')) {
          await this.putConfiguration(configuration.name, this.enableFichePrint.toString());
        }
      }

      await this.loadConfiguration();
      this.toastService.success(this.translate.instant('configuration.saveSuccess'));
    } catch {
      this.toastService.error(this.translate.instant('configuration.saveError'));
    } finally {
      this.loadingRequest = false;
    }
  }

  /**
   * Load the header authorization for requests
   * Use loadAuthData from login service and HttpHeaders
   * @returns Prepared HttpHeaders
   */
  prepareRequest(): HttpHeaders {
    const auth = this.login.loadAuthData();
    const headers = new HttpHeaders().set('Authorization', `bearer ${auth.token}`);
    return headers;
  }

  /**
   * Get the configuration metadata
   * @returns an Configuration promise.
   */
  public getConfiguration(): Promise<Configuration[]> {
    const headers = this.prepareRequest();

    return this.http.get<Configuration[]>(`${API}/metadata/configuration`, { headers }).toPromise();
  }

  /**
   * Get the configuration metadata
   * @returns an AuthData promise.
   */
  public putConfiguration(name: string, value: string): Promise<PutConfigurationResponseDto> {
    const headers = this.prepareRequest();
    const body = {
      name,
      value,
    };

    return this.http.put<PutConfigurationResponseDto>(`${API}/metadata/configuration`, body, { headers }).toPromise();
  }
}
