<div class="dashboard-container">
  <div class="overall-bar">
    <div class="title">
      <div class="left-elements">
        <div [ngSwitch]="type">
          <div *ngSwitchCase="'deflector'">
            PROGRAMAÇÃO - {{ ('dashboards.deflector' | translate).toUpperCase() }}
            {{ page }}
          </div>

          <div *ngSwitchCase="'bumper'">
            PROGRAMAÇÃO - {{ ('dashboards.bumper' | translate).toUpperCase() }} {{ page }}
          </div>

          <div *ngSwitchCase="'mudguard'">PROGRAMAÇÃO - {{ ('dashboards.mudguard' | translate).toUpperCase() }}</div>
        </div>
        <span style="margin: 0 1rem">•</span>
        <div>{{ mesAtual }} - {{ anoAtual }}</div>
      </div>

      <!-- Shift information -->
      <div class="shift-group">
        <div *ngFor="let shift of boxQuantityPerShift">
          <span>{{ shift.shift }}</span>
          <div>Abertas: {{ shift.opened }}</div>
          <div>Fechadas: {{ shift.closed }}</div>
        </div>
      </div>

      <div class="right-elements">
        <div *ngIf="loading" class="spinner-border spinner-border-sm text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <img src="../../../assets/images/boxpart-minilogo.svg" />
      </div>
    </div>
    <div class="bar">
      <div [style.width]="((boxQuantityPerState[0] / total) * 100).toString() + '%'" class="available"></div>
      <div [style.width]="((boxQuantityPerState[1] / total) * 100).toString() + '%'" class="opened"></div>
      <div [style.width]="((boxQuantityPerState[2] / total) * 100).toString() + '%'" class="suspended"></div>
      <div
        [style.width]="(((boxQuantityPerState[3] + boxQuantityPerState[4]) / total) * 100).toString() + '%'"
        class="closed"></div>
      <div [style.width]="((boxQuantityPerState[5] / total) * 100).toString() + '%'" class="dispatched"></div>
    </div>
    <div class="description">
      <div>
        <i class="fas fa-circle" style="color: #c02f1d"></i>
        {{ boxQuantityPerState[0] }} <br />
        Disponíveis
      </div>
      <div>
        <i class="fas fa-circle" style="color: #f26d21"></i>
        {{ boxQuantityPerState[1] }} <br />
        Abertas
      </div>
      <div>
        <i class="fas fa-circle" style="color: #0d3d56"></i>
        {{ boxQuantityPerState[2] }} <br />
        Suspensas
      </div>
      <div>
        <i class="fas fa-circle" style="color: #ebc944"></i>
        {{ boxQuantityPerState[3] }} <br />
        Fechadas
      </div>
      <div>
        <i class="fas fa-circle" style="color: #a3b86c"></i>
        {{ boxQuantityPerState[4] }} <br />
        Despachadas
      </div>
    </div>
  </div>
  <div class="detail-container">
    <div class="content-area" #contentArea>
      <div *ngIf="!loading && !boxes.length" class="emptySequencingDay">Não há sequências para este dia</div>

      <table class="table table-hover table-bordered table-striped" style="table-layout: fixed" *ngIf="boxes.length">
        <thead #tableHead>
          <tr>
            <th style="width: 7em">Data</th>
            <th style="width: 7em">Ref.</th>
            <th style="width: 5em" *ngIf="type === 'deflector'">{{ 'POPID' }}</th>
            <th style="width: 3.5em" *ngIf="type === 'bumper'">{{ 'C.U.' }}</th>
            <th style="width: 5em" *ngIf="type === 'deflector'">Cor</th>
            <th colspan="14">
              Sequenciamento por
              <span [ngSwitch]="type">
                <span *ngSwitchCase="'deflector'">Peças</span>
                <span *ngSwitchCase="'bumper'">Cod. Bruto + Cor</span>
                <span *ngSwitchCase="'mudguard'">POPID + C.U.</span>
              </span>
            </th>
            <th style="width: 3.5em">Qtd.</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let box of boxes" class="align-bottom" #boxLine>
            <td style="color: #3e69a9; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
              <b>{{ box.sequenceDate | date: 'shortDate' }}</b>
            </td>
            <td class="ref" [style.--bg-color]="isBoxAvailable(box) ? '#0C6600' : '#c02f1d'">
              <b>{{ box.supplier }}_{{ box.refNumber }}</b>
            </td>
            <td style="color: #3e69a9" *ngIf="type === 'deflector'">
              <b> {{ box.popIds[0] }}</b>
            </td>
            <td style="color: #3e69a9" *ngIf="type === 'bumper'">
              <b> {{ box.parts[0].cuCode }}</b>
            </td>
            <td style="color: #3e69a9" *ngIf="type === 'deflector'">
              <b>{{ colorsMetadata[box.colors[0]].colorCode }}</b>
            </td>
            <td
              *ngFor="let part of box.parts"
              [style.--bg-color-pn]="partBackgroud(part)"
              style="min-width: 5rem; text-align: center; font-weight: 700"
              class="part-number">
              <span [ngSwitch]="type">
                <span *ngSwitchCase="'deflector'">{{ part.grossPartNumber }}</span>
                <span *ngSwitchCase="'bumper'"
                  >{{ part.grossPartNumber }} <br />
                  {{ colorsMetadata[part.colorNumber].colorCode }}
                </span>
                <span *ngSwitchCase="'mudguard'"
                  >{{ part.popId }} <br />
                  {{ part.cuCode }}
                </span>
              </span>
            </td>
            <td>{{ countBoxedParts(box.parts) }}/{{ countTotalParts(box.parts) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
