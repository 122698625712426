import { formatDate } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  boxesQuantityPerShiftDto,
  sequencingKittingDatalakeBox,
  sequencingKittingDatalakePart,
} from '@atypes/dashboard.types';
import { TranslateService } from '@ngx-translate/core';
import { AppToastService } from '@services/apptoast.service';
import { DashboardService } from '@services/dashboard.service';
import { ErrorMessageService } from '@services/error-message.service';
import { MetadataService } from '@services/metadata.service';

@Component({
  selector: 'app-sequencing-dashboard',
  templateUrl: './sequencing-dashboard.component.html',
  styleUrls: ['./sequencing-dashboard.component.scss'],
})
export class SequencingDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('contentArea') contentArea: ElementRef | undefined;
  @ViewChild('boxLine') boxLine: ElementRef | undefined;
  @ViewChild('tableHead') tableHead: ElementRef | undefined;

  type = 'deflector1';
  page = 0;

  boxQuantityPerShift: boxesQuantityPerShiftDto = [];
  boxQuantityPerState: number[] = [];
  total = 0;
  boxes: sequencingKittingDatalakeBox[] = [];
  loading: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  colorsMetadata: any;

  interval: NodeJS.Timeout;

  //Weights for sorting PN from deflector
  CUweights = {
    '7713': 0,
    '7727': 1,
    '7730': 2,
    '7728': 3,
    '7731': 4,
    '7717': 5,
    '7719': 6,
    '7715': 7,
    '10377': 8,
    '10378': 9,
    '7723': 10,
    '7725': 11,
  };


  meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  mesAtual;
  anoAtual;

  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private metadataService: MetadataService,
    private translate: TranslateService,
    private toast: AppToastService,
    private errorMessageService: ErrorMessageService
  ) {
    this.loading = true;
    this.colorsMetadata = this.metadataService.getColors();

    this.interval = setInterval(() => {
      this.update();
    }, 60 * 1000); // 1 Minute
  }

  ngOnInit() {
    return;
  }

  async ngAfterViewInit(): Promise<void> {
    setTimeout(() => {
      this.route.paramMap.subscribe(parameterMap => {
        const type = parameterMap.get('type') ?? '';

        if (type === 'deflector1' || type === 'deflector2' || type === 'deflector3' || type === 'bumper1' || type === 'bumper2') {
          this.type = type.slice(0, -1);
          this.page = Number(type.slice(-1));
        } else {
          this.type = type;
          this.page = 1;
        }

        this.update();
      });
    }, 0);
  }

  ngOnDestroy(): void {
    if (this.interval) clearInterval(this.interval);
  }

  countBoxedParts(parts: { partNumber: string; popId: string; status: number }[]): number {
    return parts.filter(part => part.status === 2).length;
  }

  countTotalParts(parts: { partNumber: string; popId: string; status: number }[]): number {
    return parts.filter(part => part.partNumber !== '').length;
  }

  async update() {
    this.loading = true;

    /** Sets the current day and checks the shift pass to calculate the date that will be used in the API call */
    const date = new Date();
    if (date.getHours() >= 0 && date.getHours() < 4) date.setDate(date.getDate() - 1);

    this.mesAtual = this.meses[date.getMonth()];
    this.anoAtual = date.getFullYear();

    /** Transform Date to API format (yyyyMMdd) and call the API service */
    const boxesQuantityPerShiftDate = formatDate(date, 'yyyyMMdd', navigator.language);
    this.boxQuantityPerShift = await this.dashboardService
      .getBoxesQuantityPerShift(boxesQuantityPerShiftDate, this.type)
      .toPromise();

    const boxHeight = this.boxLine?.nativeElement.offsetHeight ?? 33;
    const tableHeadHeight = this.tableHead?.nativeElement.offsetHeight ?? 40;
    const tableAvailableSpace = this.contentArea?.nativeElement.offsetHeight - tableHeadHeight;

    this.dashboardService.setCurrentlyQuantityPerPage(Math.trunc(tableAvailableSpace / boxHeight));

    await this.dashboardService
      .getSequencingKitting(this.type, 3 * this.dashboardService.getCurrentlyQuantityPerPage(), [0, 2])
      .toPromise()
      .then(
        res => {
          res.map(item => {
            item.parts = item.parts.sort((a, b) => this.CUweights[a.cuCode] - this.CUweights[b.cuCode]);

            if (item.parts.length < 14) {
              for (let i = item.parts.length; i < 14; i++) {
                item.parts.push({
                  partNumber: '',
                  popId: '',
                  status: -1,
                  cuCode: '',
                  isAvailable: true,
                  grossPartNumber: '',
                  colorNumber: '',
                });
              }
            }
          });

          this.boxes = [];
          this.total = 0;
          this.boxes = res;

          this.boxes = this.boxes.filter(box => box.status <= 2);

          const start = this.dashboardService.getCurrentlyQuantityPerPage() * (this.page - 1);
          const end = this.dashboardService.getCurrentlyQuantityPerPage() * this.page;

          this.boxes = this.boxes.slice(start, end);
        },
        error => {
          const code = error.error.code ? error.error.code : error.code ? error.code : '-1';
          this.toast.error(this.errorMessageService.translate(code));
        }
      )
      .finally(() => {
        this.loading = false;
      });

    await this.dashboardService
      .getBoxesStatesQuantity(formatDate(date, 'yyyyMM', navigator.language))
      .toPromise()
      .then(
        res => {
          this.boxQuantityPerState = [];
          this.total = res.available + res.opened + res.suspended + res.closed + res.dispatched;
          this.boxQuantityPerState.push(res.available);
          this.boxQuantityPerState.push(res.opened);
          this.boxQuantityPerState.push(res.suspended);
          this.boxQuantityPerState.push(res.closed + res.inTruck);
          this.boxQuantityPerState.push(res.dispatched);
        },
        error => {
          const code = error.error.code ? error.error.code : error.code ? error.code : '-1';
          this.toast.error(this.errorMessageService.translate(code));

          this.total = 0;
          this.boxQuantityPerState.push(0);
          this.boxQuantityPerState.push(0);
          this.boxQuantityPerState.push(0);
          this.boxQuantityPerState.push(0);
          this.boxQuantityPerState.push(0);
          this.boxQuantityPerState.push(0);
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }

  isBoxAvailable(box: sequencingKittingDatalakeBox) {
    return !box.parts.some(part => part.status === 0 && !part.isAvailable);
  }

  partBackgroud(part: sequencingKittingDatalakePart): string {
    if (part.status === 2) return '#9CEDAE';
    if (part.status === 1) return '#FFE7A1';
    if (part.status === 0 && !part.isAvailable) return '#FCA99F';

    return ''; // is available and not tagged
  }
}
